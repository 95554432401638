import { PropsWithChildren, useEffect } from 'react';
import dayjs from 'dayjs';
import { I18nextProvider } from 'react-i18next';

import { VIEWER_CURRENCY_COOKIE } from '../cookies/constants';
import { setClientCookie } from '../cookies/utils/set-client-cookie';
import { useUser } from '../user/hooks/use-user';

import { getCurrencyByCountryCode } from './utils/get-currency-by-country-code';
import { getViewerCurrency } from './utils/get-viewer-currency';
import { getViewerCountryCode } from './utils/get-viewer-geo-codes';
import { updateDocumentLocale } from './utils/update-document-locale';
import { i18n } from './internationalization';

export function WithInternationalization({ children }: PropsWithChildren) {
  const { userPreferences, userPreferencesIsLoading } = useUser();
  const preferredLanguage = userPreferences?.preferredLanguage;
  const preferredCurrency = userPreferences?.preferredCurrency;

  // If the user has set a preferred language, use that. Otherwise, use the language detected by i18next.
  let language = preferredLanguage ?? i18n.language;

  // Options: https://github.com/iamkun/dayjs/tree/dev/src/locale
  if (language === 'ar') {
    import('dayjs/locale/ar');
    dayjs.locale('ar');
  }

  if (language === 'de') {
    import('dayjs/locale/de');
    dayjs.locale('de');
  }

  if (language === 'es') {
    import('dayjs/locale/es');
    dayjs.locale('es');
  }

  if (language === 'fr') {
    import('dayjs/locale/fr');
    dayjs.locale('fr');
  }

  if (language === 'fr-CA') {
    import('dayjs/locale/fr-ca');
    dayjs.locale('fr-ca');
  }

  if (language === 'ko') {
    import('dayjs/locale/ko');
    dayjs.locale('ko');
  }

  if (language === 'pt') {
    import('dayjs/locale/pt');
    dayjs.locale('pt');
  }

  if (language === 'pt-BR') {
    import('dayjs/locale/pt-br');
    dayjs.locale('pt-br');
  }

  if (language === 'ru') {
    import('dayjs/locale/ru');
    dayjs.locale('ru');
  }

  if (language === 'tr') {
    import('dayjs/locale/tr');
    dayjs.locale('tr');
  }

  /**
   * Determine the currency to use.
   * If the user has set a preferred currency, use that. Otherwise, use the currency for the user's viewer_country cookie.
   * Ensure the user has a viewer_currency cookie set.
   * Ensure the viewer_currency cookie is up-to-date with their preferred currency.
   */
  useEffect(() => {
    if (userPreferencesIsLoading) return;

    const viewerCurrency = getViewerCurrency();

    // User does not have the currency cookie, or it's different than their preferred currency.
    if (!viewerCurrency || viewerCurrency !== preferredCurrency) {
      // If the user has a preferred currency, set the cookie to that.
      if (preferredCurrency) {
        setClientCookie(VIEWER_CURRENCY_COOKIE, preferredCurrency, 365);
      }

      // If the user does not have a preferred currency, set the cookie to the currency for their viewer_country cookie.
      else {
        const viewerCountryOrUndefined = getViewerCountryCode();
        const currencyOrUndefined = getCurrencyByCountryCode(viewerCountryOrUndefined);
        // If the user's viewer_country cookie is set to a country we don't support, or not set at all, default to USD.
        setClientCookie(VIEWER_CURRENCY_COOKIE, currencyOrUndefined ?? 'USD', 365);
      }
    }
  }, [preferredCurrency, userPreferencesIsLoading]);

  useEffect(() => {
    i18n.changeLanguage(language);
    dayjs.locale(language);
    updateDocumentLocale(i18n);
  }, [language]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}
