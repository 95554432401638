import { ExperimentPage } from '~/cf-worker/types';

export const experimentedPages: ExperimentPage[] = [
  {
    name: 'Subscription Pause', // https://app.amplitude.com/experiment/udacityPOC/218461/config/288999/configure
    experimentKey: 'subscription_pause',
    pathRegex: /^\/settings\/subscription$/,
    options: {
      bucketingStrategy: 'user_id',
      requiresAuth: true,
    },
  },
  {
    name: 'Quick Authentication AB Test', // https://app.amplitude.com/experiment/udacityPOC/218461/config/299324/settings
    experimentKey: 'ab_quick_checkout_value',
    pathRegex: /^\/quick-checkout/,
  },
  {
    name: 'Homepage School Contextualization AB Test', // https://app.amplitude.com/experiment/udacityPOC/218461/config/301813/settings
    experimentKey: 'ab_hp_schools',
    pathRegex: /^\/$/,
  },
];
